<template>
  <div class="app flex-row align-items-center"  v-if='!this.isPages' >
    <b-jumbotron class='bg-transparent'>
      <template #header>Oops, There is Some Error</template>
      <div class='p-3'>
        <h5 class='card-title mb-0 font-weight-bold' v-if='this.message'>{{this.message}}</h5>
        <h5 class='card-title mb-0 font-weight-bold' v-else>Error Not Found, Please Kindly Try Again</h5>
      </div>
    
      <router-link class="btn btn-sm btn-dark  "  size="md"  :to="{ path: '/'}">
        <em class="fa fa-backward btn-sm btn-dark "></em> Return To Home Page
      </router-link>
    </b-jumbotron>
  </div>
  <div class="app flex-row align-items-center" v-else>
    <div class="container mt-3">
      <router-link class="btn btn-sm btn-dark"  size="sm"  :to="{ path: `/twoFactor/${this.token}`}">
        <em class="fa fa-backward btn-sm btn-dark "></em> Back
      </router-link>
      <b-row class="row justify-content-center">
        <b-col md="6" sm="0" class="left mt-6 display-mobile">
          <img
            :src="qrCode"
            height="600px"
            width="600px"
          />
        </b-col>
        <b-col md="6" class="right">
          <b-card-group>
              <b-card-body class="background-mobile">
                <b-row align-v="center">
                  <b-col class="text-center">
                  <img
                    src="../../assets/img/gramedia_digital.png"
                    alt="admin@bootstrapmaster.com"
                    class="m-5"
                  />
                  </b-col>
                </b-row>
                <b-form @submit.prevent="actionSave">
                  <h1 class="h2 text-center">  Digital Books Gramedia </h1>
                  <h3 class="text-center">  Sign Up With Two Factor Authentication </h3>
                  <div v-if='(this.key)'>
                    <b-row>
                      <b-col>
                        <h6 class="text-center my-3" style="color:red">Do Not Give This Key to Others</h6>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <wording-scan-barcode-vue></wording-scan-barcode-vue>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="text-center">
                    <b-button variant='primary' type='submit'>Save Secret Key</b-button>
                  </div>
                </b-form>
              </b-card-body>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import wordingScanBarcodeVue from './wording-scan-barcode.vue';
export default {
  name: "twoFactor",
  components: {
    wordingScanBarcodeVue,
  },
  data() {
    return {
      form: {key:null,},
      isActive: false,
    };
  },created(){
    let token=this.$route.params.token;
    this.token = this.$route.params.token;
    this.generateSecretKey({token});
    this.setSecretKey();
    
  },
  computed: {
    ...mapState({
      success: (state) => state.login.isSuccess,
      message: (state) => state.login.successMessage,
      isPages : (state) => state.login.isPage,
      isLoading: (state) => state.login.isLoading,
      isError: (state) =>state.login.isError,
      page: (state) => state.login.page,
      key : (state) => state.login.key,
      qrCode: (state) =>state.login.qrCode,
    }),
  },
  watch: {
    $route: {
        immediate: true,
        handler(to) {
          document.title = to.meta.title || 'Two Factor Authentication | Content Management System Ebooks Gramedia.com';
        }
    },
    key: function(){
      this.setSecretKey();
    },
    isError: function(){
      if(this.isError){
        if(this.message){
          this.showAlert({
          success: false,
          message: this.message,
          title: "Error Create Access",
          });
        }else{
          this.showAlert({
          success: false,
          message: "Error Create Access",
          title: "Error Create Access",
          });
        }
      }
      this.setSecretKey();
    },
    success: function (){
      if(this.success){
          this.showAlert({
          success: true,
          message: this.message,
          title: "Success Create New Secret Key",
          });
          this.$router.push({
            path: `/twoFactor/${this.token}`,
          })
        }
      }
    },
  methods: {
    ...mapActions("login", ["generateSecretKey","saveSecretKey"]),
    
    actionSave() {
      this.$swal
        .fire({
          title: "Are You Sure You Have Scan the Barcode?",
          text: `This Action Cannot be Rollback`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            let payload = { 
              token:this.token,
              secretKey: this.key,
            };
            this.saveSecretKey(payload);
          }
        });
    },copyAction(){
      const element = this.form.key;
      navigator.clipboard.writeText(element);
    },
    showAlert(data) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon: data.success ? "success" : "error",
        title: data.message,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },createNewAuth(e) {
      e.preventDefault();
          this.$router.push({
          path: `/newAuth/${this.token}`,
        });
    },
    setSecretKey() {
      this.form.key = this.key;
    },
  },
  mounted() {
    let token = localStorage.getItem("session_token");
    if (token) {
      this.$router.push({ path: "/" });
    }
   
  },
};
</script>
<style lang="css" scoped>
.center {
  width: 500px;
  height: 400px;
  top: 20%;
  position: absolute;
  top: 20%;
  margin-left: auto;
  margin-right: auto;
}
.form-control {
  border: 0;
  border-bottom: 1px solid #000;
  outline: none;
}
input.form-control:focus {
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
@media (min-width: 360px) and (max-width: 768px) {
  .background-mobile {
    background-color: #fff;
  }
  .display-mobile {
    display: none;
  }
}

</style>
