var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', [_c('span', {
    staticClass: "font-weight-bold font-italic"
  }, [_vm._v("NOTED :")]), _c('br'), _c('span', {
    staticClass: "text-justify"
  }, [_vm._v("1. Please scan this barcode using the Google Authenticator Application which can be downloaded on "), _c('a', {
    attrs: {
      "href": _vm.linkAndroid,
      "target": "_blank"
    }
  }, [_vm._v("Google Play")]), _vm._v(" (Android) or "), _c('a', {
    attrs: {
      "href": _vm.linkIos,
      "target": "_blank"
    }
  }, [_vm._v("App Store")]), _vm._v(" (iOS)")]), _c('br'), _c('span', [_vm._v("2. Click Button Save Secret Key")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }