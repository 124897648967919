<template>
  <div>
    <p>
      <span class="font-weight-bold font-italic">NOTED :</span>
      <br>
      <span class="text-justify">1. Please scan this barcode using the Google Authenticator Application which can be downloaded on <a :href="linkAndroid" target="_blank">Google Play</a> (Android) or <a :href="linkIos" target="_blank">App Store</a> (iOS)</span>
      <br>
      <span>2. Click Button Save Secret Key</span>
    </p>
  </div>
</template>
<script>
export default {
  name: 'WordingScanBarcode',
  data(){
    return {
      linkAndroid: 'https://play.google.com/store/search?q=GOOGLE%20AUTHENTICATOR&c=apps&hl=id-ID',
      linkIos: 'https://apps.apple.com/id/app/google-authenticator/id388497605?l=id',
    }
  }
}
</script>